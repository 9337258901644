import React from "react";
import { first } from "lodash/fp";
import { Listing } from "storefront/Listing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { AlgoliaMyItemsListing } from "storefront/Listing/AlgoliaMyItemsListing";
import { Badge as BadgeType } from "storefront/Badge";
import { getBadgesFromConfig } from "storefront/Config/PublicConfig";
import useAuthentication from "storefront/hooks/useAuthentication";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import Badge from "storefront/components/Badge";
import ListingBadge from "storefront/components/ListingBadge";

type Props = {
  listing:
    | Listing
    | AlgoliaListing
    | AlgoliaMyItemsListing
    | GrailedAPILightListing;
};

const firstBadgeName = ({
  badges,
}: Listing | AlgoliaListing | AlgoliaMyItemsListing | GrailedAPILightListing):
  | string
  | null
  | undefined => first(badges);

// If there is more than one listing badge, we want to display just one.
const getSingleListingBadge = (
  listing:
    | Listing
    | AlgoliaListing
    | AlgoliaMyItemsListing
    | GrailedAPILightListing,
  badges: Array<BadgeType>,
): BadgeType | null | undefined =>
  badges.find((badge) => firstBadgeName(listing) === badge.name);

const ListingItemBadge = ({ listing }: Props) => {
  const auth = useAuthentication();
  const config = usePublicConfig();
  const badges = getBadgesFromConfig(config);
  const singleBadge = getSingleListingBadge(listing, badges);

  if ("sold" in listing && listing.sold)
    return <Badge variant="primary">Sold</Badge>;

  if (singleBadge) return <ListingBadge badge={singleBadge} />;

  // Only Heavy and Algolia Listing have `shipping`
  const hasFreeUSShipping =
    "shipping" in listing &&
    listing?.shipping?.us.enabled &&
    listing?.shipping?.us.amount === 0;

  if (
    hasFreeUSShipping &&
    (auth.type !== "Authenticated" || auth.user.locationAbbreviation === "us")
  )
    return <Badge variant="secondary">Free Shipping</Badge>;

  return null;
};

export default ListingItemBadge;
