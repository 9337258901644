import { Listing } from "storefront/Listing";
import { AlgoliaMyItemsListing } from "storefront/Listing/AlgoliaMyItemsListing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { Money, usd } from "storefront/Money";

/**
 * @name currentPrice
 * @memberof Listing
 * @description Returns the price of the listing if _any_ buyer were to purchase it right now,
 * without considering offers.
 *
 * If you're interested in the original price of the listing, see Listing.originalPrice.
 * If you're interested in the sold price of the listing, see Listing.soldPrice.
 */
const currentPrice = (
  listing:
    | Listing
    | AlgoliaMyItemsListing
    | GrailedAPIHeavyListing
    | GrailedAPILightListing
    | AlgoliaListing,
): Money<"USD"> => usd(listing.price);

export default currentPrice;
