import { Id, emptyId } from "storefront/lib/Id";
import { Name as LocationName } from "storefront/Shipping/Region";
import { isBefore } from "date-fns/fp";

// Derived from MAX_AMOUNT constant defined in the offer model (backend)
// Make sure that these values always match
export const MAX_OFFER = 200000;

export const UNKNOWN_SHIPPING = "unknown_shipping" as const;
type UnknownShippingType = { type: typeof UNKNOWN_SHIPPING };

export const GRAILED_LABEL = "grailed_label" as const;
type GrailedLabelShippingType = {
  type: typeof GRAILED_LABEL;
  freeShipping: boolean;
  expeditedShipping: boolean;
};

export const MY_OWN_SHIPPING = "my_own_shipping" as const;
type MyOwnShippingType = { type: typeof MY_OWN_SHIPPING; amount: number };

type ShippingInfoType =
  | UnknownShippingType
  | GrailedLabelShippingType
  | MyOwnShippingType;

export type Offer = {
  id: Id;
  type: "offer" | "accepted_offer";
  createdAt: string;
  senderId: Id;
  amount: number;
  amountIncludesShipping: boolean;
  expiresAt: string | null | undefined;
  offerType: "binding" | "non_binding";
  voided: boolean;
  paymentPending: boolean;
  buyerShippingRegion: LocationName | null;
  shippingInfo: ShippingInfoType;
};

export const empty: Offer = {
  id: emptyId,
  type: "offer",
  createdAt: "",
  senderId: emptyId,
  amount: 0,
  amountIncludesShipping: false,
  expiresAt: null,
  offerType: "non_binding",
  voided: false,
  paymentPending: false,
  buyerShippingRegion: null,
  shippingInfo: { type: UNKNOWN_SHIPPING },
};

export const isBinding = (offer: Offer) => offer.offerType === "binding";

export const isAccepted = (offer: Offer) => offer.type === "accepted_offer";

export const isExpired = ({ expiresAt }: Offer) =>
  !!expiresAt && isBefore(new Date())(new Date(expiresAt));

export const isUnknownShipping = (
  shippingInfo: ShippingInfoType,
): shippingInfo is UnknownShippingType =>
  shippingInfo.type === UNKNOWN_SHIPPING;

export const isGrailedLabelShippingType = (
  shippingInfo: ShippingInfoType,
): shippingInfo is GrailedLabelShippingType =>
  shippingInfo.type === GRAILED_LABEL;

export const isMyOwnShippingType = (
  shippingInfo: ShippingInfoType,
): shippingInfo is MyOwnShippingType => shippingInfo.type === MY_OWN_SHIPPING;
