import React from "react";
import type { IndexedList } from "storefront/lib/IndexedList";
import type { Badge as BadgeType, BadgeName } from "storefront/Badge";
import Badge from "storefront/components/Badge";
import type { Variant } from "storefront/components/Badge";
import ReactTooltipLite from "storefront/components/ReactTooltipLite";

import styles from "./ListingBadge.module.scss";

type Props = {
  badge: BadgeType;
  className?: string;
};
const variantsByName: IndexedList<Variant> = {
  staff_pick: "inactive",
  by_grailed: "secondary",
};

const nameToVariant = (name: BadgeName): Variant => variantsByName[name];

// https://app.shortcut.com/grailed-team/story/49719/remove-rollover-image-on-grailedofficial-listing-badges
const shouldShowHoverDetail = (
  description: string | undefined,
  name: BadgeName,
): boolean => Boolean(description && name !== "by_grailed");

const ListingBadge = ({ badge, className = "" }: Props) => {
  const { name, description, displayName } = badge;
  const showHoverDetail = shouldShowHoverDetail(description, name);

  return (
    <Badge variant={nameToVariant(name)} className={className}>
      {showHoverDetail ? (
        <ReactTooltipLite
          content={description}
          className={`${styles.tooltip} ${styles["_white-tooltip"]}`}
          tipContentHover
        >
          {displayName}
        </ReactTooltipLite>
      ) : (
        displayName
      )}
    </Badge>
  );
};

export default ListingBadge;
