import slugify from "storefront/slugify";
import type { Id } from "storefront/lib/Id";

type ListingPathable = {
  prettyPath?: string;
  id: Id;
  designerNames: string;
  title: string;
};

const listingPath = ({
  prettyPath,
  id,
  designerNames,
  title,
}: ListingPathable): string => {
  return prettyPath || `/listings/${id}-${slugify(designerNames, title)}`;
};

export default listingPath;
