/**
 * @namespace SearchParams
 * @description In some cases, we use parameters in the URL to communicate information; e.g. a
 * piece of information that needs to be persisted from one page to another, but isn't appropriate
 * for a cookie or local storage.
 */

/**
 * @name set
 * @memberof SearchParams
 */
export const set =
  (name: string, value: string) =>
  (urlOrPath: string): string => {
    const [base, params] = urlOrPath.split("?");
    const urlParams = new URLSearchParams(params);
    urlParams.set(name, value);
    return `${base}?${urlParams.toString()}`;
  };

/**
 * @name get
 * @memberof SearchParams
 */
export const get =
  (name: string) =>
  (urlOrPath: string): string | null | undefined => {
    const [_, params] = urlOrPath.split("?");
    const urlParams = new URLSearchParams(params);
    return urlParams.get(name);
  };
