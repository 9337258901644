import * as SearchParams from "storefront/lib/SearchParams";

/**
 * @namespace GrailedSearchParams
 */

/**
 * @private
 * @description We prefix all of our URL parameter names with "g_" to differeniate them from
 * parameters that might be used by other services; e.g. "utm_", etc.
 */
const PREFIX = "g";

/**
 * @name set
 * @memberof GrailedSearchParams
 */
export const set = (name: string, value: string) =>
  SearchParams.set(`${PREFIX}_${name}`, value);

/**
 * @name get
 * @memberof GrailedSearchParams
 */
export const get = (name: string) => SearchParams.get(`${PREFIX}_${name}`);

/**
 * @name setAlgoliaQueryId
 * @memberof GrailedSearchParams
 */
export const setAlgoliaQueryId = (value: string) => set("aqid", value);

/**
 * @name getAlgoliaQueryId
 * @memberof GrailedSearchParams
 */
export const getAlgoliaQueryId = get("aqid");

/**
 * @name setAlgoliaIndex
 * @memberof GrailedSearchParams
 */
export const setAlgoliaIndex = (value: string) => set("aidx", value);

/**
 * @name getAlgoliaIndex
 * @memberof GrailedSearchParams
 */
export const getAlgoliaIndex = get("aidx");

/**
 * @name setAlgoliaAbTestId
 * @memberof GrailedSearchParams
 */
export const setAlgoliaAbTestId = (value: string) => set("abtid", value);

/**
 * @name getAlgoliaAbTestId
 * @memberof GrailedSearchParams
 */
export const getAlgoliaAbTestId = get("abtid");

/**
 * @name setAlgoliaAbTestVariantId
 * @memberof GrailedSearchParams
 */
export const setAlgoliaAbTestVariantId = (value: string) => set("abvid", value);

/**
 * @name getAlgoliaAbTestVariantId
 * @memberof GrailedSearchParams
 */
export const getAlgoliaAbTestVariantId = get("abvid");
