import React from "react";
import TooltipLite from "react-tooltip-lite";

type Props = {
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  direction?: string;
  hoverDelay?: number;
  isOpen?: boolean;
  tipContentHover?: boolean;
  useHover?: boolean;
  padding?: string;
  arrowSize?: number;
};

/**
 * @name Basics.Tooltip
 */
const ReactTooltipLite = ({
  className,
  content,
  direction,
  hoverDelay,
  isOpen,
  tipContentHover,
  useHover,
  children,
  padding = "5px 10px",
  arrowSize,
}: Props) => (
  <TooltipLite
    className={className}
    content={content}
    direction={direction}
    hoverDelay={hoverDelay}
    isOpen={isOpen}
    tipContentHover={tipContentHover}
    useHover={useHover}
    padding={padding}
    arrowSize={arrowSize}
  >
    {children}
  </TooltipLite>
);

export default ReactTooltipLite;
