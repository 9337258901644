import type { UserSelf } from "storefront/User";
import {
  isConfirmed as userIsConfirmed,
  isAdmin as userIsAdmin,
  isCurator as userIsCurator,
} from "storefront/User";
import type { Error } from "storefront/GrailedAPI/v1/Error";
import type { Authentication } from "./index";

/**
 * @name extract
 * @memberof Authentication
 * @description A function that allows us to extract some value out of the user if
 * the authentication type is Authenticated and do something else if not
 */
function extract<T>(
  onAuthenticated: (user: UserSelf) => T,
  onUnauthenticated: () => T,
  onFailed?: (error: Error) => T,
  onLoading?: () => T,
): (auth: Authentication) => T {
  return (auth) => {
    switch (auth.type) {
      case "Authenticated":
        return onAuthenticated(auth.user);

      case "Unauthenticated":
        return onUnauthenticated();

      case "Failed":
        return onFailed ? onFailed(auth.error) : onUnauthenticated();

      case "Loading":
        return onLoading ? onLoading() : onUnauthenticated();

      default:
        throw new window.Error("Impossible state.");
    }
  };
}

export default extract;
export const isConfirmed = extract<boolean>(userIsConfirmed, () => false);
export const isAdmin = extract<boolean>(userIsAdmin, () => false);
export const isCurator = extract<boolean>(userIsCurator, () => false);
