import { Listing } from "storefront/Listing";
import { GrailedAPIHeavyListing } from "./GrailedAPIHeavyListing";
import { ShippingLabelField } from "./ShippingLabelField";

/* NOTE: As of now, this function only transforms shippingLabel.sellerPrice from string to number when there's a shippingLabel.
[Danni 2021-04-26]
*/

/** `shippingLabel` is not on LightListing */
export type ActualGrailedAPIHeavyListing = Omit<
  GrailedAPIHeavyListing,
  "shippingLabel"
> & {
  shippingLabel: Omit<ShippingLabelField, "sellerPrice"> & {
    sellerPrice: string;
  };
};

export const formatGrailedAPIHeavyListing = (
  listing: ActualGrailedAPIHeavyListing,
): GrailedAPIHeavyListing => {
  const { shippingLabel } = listing;
  if (!shippingLabel) return { ...listing, shippingLabel: null };

  const sellerPrice = parseInt(shippingLabel.sellerPrice, 10);

  return {
    ...listing,
    shippingLabel: { ...shippingLabel, sellerPrice },
  };
};

// This should only be used temporarily, while we fix our Listing type everywhere
export type ActualGrailedAPIListing = Omit<Listing, "shippingLabel"> & {
  shippingLabel: Omit<ShippingLabelField, "sellerPrice"> & {
    sellerPrice: string;
  };
};

/**
 * @param listing
 * @returns properly formatted Listing for our frontend -
 * MUST use at an API call level when fetching anything with a listing
 */
const formatListing = (listing: ActualGrailedAPIListing): Listing => {
  const { shippingLabel } = listing;

  if (!shippingLabel) return { ...listing, shippingLabel: null };

  const sellerPrice = parseInt(shippingLabel.sellerPrice, 10);

  return { ...listing, shippingLabel: { ...shippingLabel, sellerPrice } };
};

export default formatListing;
