import { Listing } from "storefront/Listing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { Money, usd } from "storefront/Money";

/**
 * @name soldPrice
 * @memberof Listing
 * @description Returns the price at which the buyer of the listing purchased it from the seller.
 */
const soldPrice = (
  listing:
    | Listing
    | GrailedAPIHeavyListing
    | GrailedAPILightListing
    | AlgoliaListing,
): Money<"USD"> | null | undefined =>
  listing.soldPrice ? usd(listing.soldPrice) : null;

export default soldPrice;
