import React from "react";
import { Department as DepartmentType } from "storefront/Department";

import styles from "./Department.module.scss";

const departmentToLabel = {
  menswear: "M",
  womenswear: "W",
};

type Props = {
  department: DepartmentType;
};

const Department = ({ department }: Props) => (
  <span className={styles.departmentLabel}>
    {departmentToLabel[department]}
  </span>
);

export default Department;
