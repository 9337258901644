import type { Currency, Money } from "./index";
import "./index";

/**
 * @name equals
 * @memberof Money
 */
const equals =
  <C extends Currency>(a: Money<C>): ((b: Money<C>) => boolean) =>
  (b) => {
    if (a.currency !== b.currency)
      throw new Error(`Currency Mismatch: ${a.currency}, ${b.currency}`);
    return a.amount === b.amount;
  };

export default equals;
