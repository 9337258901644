import { flow, head, parseInt, split } from "lodash/fp";
import {
  formatDistanceToNowStrict,
  isPast,
  isValid,
  parseISO,
  format,
} from "date-fns";
import type { Result } from "storefront/lib/Result";
import { failure, success } from "storefront/lib/Result";
import extract from "storefront/lib/Result/extract";
// empty ISO date string
export const emptyTimestamp = new Date().toISOString();
// takes an ISO formatted dateString and tries to convert it to a Date
export const fromString = (
  str: string | null | undefined,
): Result<Error, Date> => {
  if (typeof str === "string" && isValid(parseISO(str))) {
    return success(parseISO(str));
  }

  return failure(
    new Error(
      `Cannot parse a date from ${
        typeof str === "string" ? `"${str}"` : "null or undefined"
      }`,
    ),
  );
};
export const extractDateFromString = (
  dateString: string | null | undefined,
): Date | null | undefined =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Date | n... Remove this comment to see the full error message
  extract(
    (error) => {
      // log the parsing error for developers but return undefined
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      // eslint-disable-next-line no-console
      console.warn(error.message);
    },
    (date) => date,
  )(fromString(dateString));
export const minutesUntil = (date: Date): number => {
  if (isPast(date)) return 0;
  const minutes = flow([
    (d) =>
      formatDistanceToNowStrict(d, {
        unit: "minute",
      }),
    split(" "),
    head,
    parseInt(10),
  ])(date);
  return minutes;
};
export const formatDateString = (
  dateString: string | null | undefined,
  dateFormat: string,
  defaultValue = "",
): string => {
  const parsedDate = extractDateFromString(dateString);

  if (parsedDate) {
    return format(parsedDate, dateFormat);
  }

  return defaultValue;
};
export const formatDateAsYYYYMMDD = (
  dateString: string | null | undefined,
): number => Number(formatDateString(dateString, "yyyyMMdd", "0"));
