import React from "react";
import { formatDistance } from "date-fns/fp";
import classnames from "classnames";
import { Listing } from "storefront/Listing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaMyItemsListing } from "storefront/Listing/AlgoliaMyItemsListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { extractDateFromString } from "storefront/lib/Date";
import useAuthentication from "storefront/hooks/useAuthentication";
import { isAdmin, isCurator } from "storefront/Authentication/extract";
import Department from "./Department";
import styles from "./ListingAge.module.scss";

const now: (date: Date) => string = formatDistance(new Date());

export const ago = (str: string | null | undefined): string => {
  const date = extractDateFromString(str);
  return date ? now(date) : "";
};

const lastUpdated = (
  listing:
    | Listing
    | AlgoliaMyItemsListing
    | GrailedAPILightListing
    | AlgoliaListing,
): string | null => listing.priceUpdatedAt;

type SubComponentProps = {
  listing:
    | Listing
    | AlgoliaMyItemsListing
    | GrailedAPILightListing
    | AlgoliaListing;
};

const FirstBumped = ({ listing }: SubComponentProps) => {
  if (!("bumpedAt" in listing)) return null;

  return (
    <span className={styles.dateAgo}>
      {ago(listing.bumpedAt)}
      &nbsp;ago
    </span>
  );
};

const LastUpdated = ({ listing }: SubComponentProps) => {
  const listingLastUpdated = lastUpdated(listing);
  if (!listingLastUpdated) return null;

  return (
    <span className={styles.dateAgo}>
      {ago(listingLastUpdated)}
      &nbsp;ago
    </span>
  );
};

const UnpublishedCreatedAt = ({ listing }: SubComponentProps) => {
  if (!("createdAt" in listing)) return null;

  return (
    <span className={styles.dateAgo}>
      {ago(listing.createdAt)}
      &nbsp;ago
    </span>
  );
};

const LastUpdatedAndFirstCreated = ({ listing }: SubComponentProps) => {
  const listingLastUpdated = lastUpdated(listing);
  if (!listingLastUpdated || !("createdAt" in listing)) return null;

  return (
    <span className={styles.dateAgo}>
      {ago(listingLastUpdated)}
      &nbsp;ago
      <span className={styles.strikeThrough}>
        {`(${ago(listing.createdAt)})`}
      </span>
    </span>
  );
};

const SoldAt = ({ listing }: SubComponentProps) => {
  if (!("soldAt" in listing)) return null;

  return (
    <span className={styles.dateAgo}>
      Sold {ago(listing.soldAt)}
      &nbsp;ago
    </span>
  );
};

const Age = ({ listing }: SubComponentProps) => {
  if ("soldAt" in listing && listing.soldAt)
    return <SoldAt listing={listing} />;

  // A listing might not have a "createdAt" property if it is on the /curators page
  // or if it is unpublished. If this is the case, we use "bumpedAt" as a proxy.
  if (!("createdAt" in listing) || !listing.createdAt)
    return <FirstBumped listing={listing} />;

  if (listing.createdAt === listing.priceUpdatedAt)
    return <LastUpdated listing={listing} />;

  // TODO: priceUpdatedAt won't be nil after backfill
  // AlgoliaMyItemsListing typed listings that are unpublished / under review
  // have priceUpdatedAt: nil.
  if (!listing.priceUpdatedAt)
    return <UnpublishedCreatedAt listing={listing} />;

  return <LastUpdatedAndFirstCreated listing={listing} />;
};

type Props = {
  listing:
    | Listing
    | AlgoliaMyItemsListing
    | GrailedAPILightListing
    | AlgoliaListing;
  className?: string;
};

const ListingAge = ({ listing, className }: Props) => {
  const auth = useAuthentication();

  const displayDept = isAdmin(auth) || isCurator(auth);

  return (
    <p className={classnames(styles.listingAge, className)}>
      <Age listing={listing} />
      {displayDept ? <Department department={listing.department} /> : null}
    </p>
  );
};

export default ListingAge;
